import type {FilterItem} from '../../store/Filter.ts';
import type { SearchResult } from '../../three/utils/search/SearchManager.ts';

import {FormEvent, ChangeEvent, useEffect, useMemo, useRef, useState} from 'react'
import {ChevronRightIcon, MagnifyingGlassIcon} from '@heroicons/react/20/solid';
import {XMarkIcon} from '@heroicons/react/24/outline';
import {classNames, isMac} from '../../utils/helpers.ts';
import {useAppStore} from '../../store/Store.ts';
import {useLazyEffect} from '../../utils/hooks.ts';
import UseFiltersState from './filters/FiltersState.ts';
import {CommandMacKey} from '../../assets/icons/CommandMacKey.tsx';
import {FKey} from '../../assets/icons/FKey.tsx';
import {CtrlKey} from '../../assets/icons/CtrlKey.tsx';
import { BaseSymbol } from '../../three/symbols/BaseSymbol.ts';

type SearchFilterGroup = {
    id: string,
    label: string,
    open: boolean,
    itemsFound: boolean,
    items: FilterItem[]
}

export default function ExtendedSearch() {
    const [query, setQuery] = useState('')
    const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
    const [show, setShow] = useState(false);
    const showRef = useRef<boolean>(false);
    const [filterGroupOpen, setFilterGroupOpen] = useState<string | null>(null);
    const [hoveredSymbols, setHoveredSymbols] = useState<BaseSymbol[] | null>(null);
    const ref = useRef<HTMLDivElement>(null);
    const searchInputRef = useRef<HTMLInputElement | null>(null);
    const [hoveredFilterItem, setHoveredFilterItem] = useState<FilterItem | null>(null);
    const [selectedResultItem, setSelectedResultItem] = useState<BaseSymbol | null>(null);

    const {
        currentMeshInstance,
        filterList,
        updateActiveFilters,
        uiInteractive
    } = useAppStore((state) => {
        return {
            currentMeshInstance: state.currentMeshInstance,
            filterList: state.filterList,
            updateActiveFilters: state.updateActiveFilters,
            uiInteractive: state.uiInteractive
        }
    });

    const { activeFilters } = UseFiltersState();

    const onFilterItemHover = (filterItem: FilterItem) => {
        setHoveredFilterItem(filterItem);
    }

    const onFiltersLeave = () => {
        setHoveredFilterItem(null);
    }

    const handleClickOutside = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
            setShow(false);
        }
    };

    useEffect(() => {
        showRef.current = show;
    }, [show]);

    useLazyEffect(() => {
        currentMeshInstance?.spotlightOnFilterItem(hoveredFilterItem);
    }, [hoveredFilterItem]);

    useEffect(() => {

        const detectKeyInputs = (event: KeyboardEvent) => {
            if (isMac() ? event.metaKey : event.ctrlKey) {
                if (event.key === 'f' || event.key === 'F') {
                    event.preventDefault();
                    if (searchInputRef?.current) {
                        searchInputRef.current.focus();
                    }
                }
            }
            if (showRef.current) {
                switch (event.key) {
                    case 'ArrowUp': {
                        // @TODO go to previous result
                        break;
                    }
                    case 'ArrowDown': {
                        // @TODO go to next result
                        break;
                    }
                }
            }
        }

        const onFocusOut = (e: FocusEvent) => {
            if (ref.current?.contains(e.relatedTarget as Node)) {
                return;
            }
            // setShow(false);
        }
        ref.current?.addEventListener('focusout', onFocusOut);

        // click outside of ref will close the results
        document.addEventListener('click', handleClickOutside);
        document.addEventListener('keydown', detectKeyInputs);

        return () => {
            ref.current?.removeEventListener('focusout', onFocusOut);
            document.removeEventListener('click', handleClickOutside);
            document.removeEventListener('keydown', detectKeyInputs);
        }
    }, []);

    const performSearch = (query: string) => {
        if (currentMeshInstance) {
            const searchResults = currentMeshInstance.search(query);
            if (searchResults.length) {
                setSelectedResultItem(searchResults[0].symbol)
            }
            setSearchResults(searchResults);
        }
    }

    useLazyEffect(() => {
        performSearch(query);
        if (query.length > 0) {
            setShow(true);
        } else {
            setShow(false);
        }
    }, [query], 100);

    useLazyEffect(() => {
        if (activeFilters) {
            performSearch(query);
        }
    }, [activeFilters], 100);

    useLazyEffect(() => {
        if(currentMeshInstance){
            if (hoveredSymbols) {
                currentMeshInstance.spotlight(hoveredSymbols)
            } else {
                currentMeshInstance.spotlight([])
            }
        }
    }, [hoveredSymbols]);

    const onSymbolHover = (searchResult?: SearchResult) => {
        setHoveredSymbols(!searchResult ? null : (searchResult.duplications || [searchResult.symbol]));
    }

    const onSymbolClick = (searchResult: SearchResult) => {
        if(currentMeshInstance){
            currentMeshInstance.preventCameraReset = true;
            if (!searchResult.duplications?.length) {
                searchResult.symbol.select = true;
            } else {
                currentMeshInstance.showUsages = true;
                searchResult.duplications[0].select = true;
            }
            setShow(false);
        }
    }

    const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
        const q = e.target.value;
        setFilterGroupOpen(null);
        setQuery(q);
    }

    const onFocus = () => {
        if (query.length > 0) {
            setShow(true);
        }
    }

    const onCheckboxChange = (e: FormEvent<HTMLInputElement>, group: string, item: FilterItem) => {
        const elem = e.target as HTMLInputElement;
        updateActiveFilters(group, item, elem.checked);
    }

    const filteredFilterList = useMemo(() => {
        const toDisplay: SearchFilterGroup[] = [];

        const q = query.trim().toLowerCase();

        if (!filterList) {
            return [];
        }

        filterList.map((group) => {
            if (!q.length || q.length < 2) {
                return;
            }
            const itemsToShow: FilterItem[] = [];
            const items = group.items.filter((item) => {
                return !item.disabled;
            });

            items.map((item) => {
                if (item.label && item.label.toLowerCase().includes(q)) {
                    itemsToShow.push(item);
                }
            });
            if (group.label.toLowerCase().includes(q)) {
                toDisplay.push({
                    id: group.id,
                    label: group.label,
                    open: filterGroupOpen === group.id,
                    itemsFound: false,
                    items: items
                });
            } else if (itemsToShow.length) {
                toDisplay.push({
                    id: group.id,
                    label: group.label,
                    open: true,
                    itemsFound: true,
                    items: itemsToShow
                });
            }
        });

        return toDisplay;
    }, [filterList, query, filterGroupOpen]);

    const toggleFilterGroupOpen = (groupId: string) => {
        if (filterGroupOpen === groupId) {
            setFilterGroupOpen(null);
        } else {
            setFilterGroupOpen(groupId);
        }
    }

    const renderMatches = (matches: any) => {
        let i = 0;
        return (
            <div>
                {matches.map((match: any, index: number) => {
                    const beforeHighlight = match.value.substring(0, match.indices[0][0]);
                    const highlighted = match.value.substring(match.indices[0][0], match.indices[0][1] + 1);
                    const afterHighlight = match.value.substring(match.indices[0][1] + 1);
                    if (match.key === 'title'  || i > 0) {
                        return;
                    }
                    i += 1;
                    return (
                        <span
                          className="flex text-xs break-words items-center gap-x-1 text-base-content-500 first:pt-1"
                          key={index}
                        >
                            {match.key}:
                            <span className="rounded-md bg-base-100 inline-block p-1 break-all">
                                {beforeHighlight}
                                <span className="inline-block text-base-content-500">{highlighted}</span>
                                {afterHighlight}
                            </span>
                        </span>
                    )
                })}
            </div>
        )
    }

    const onFormSubmit = (event: FormEvent) => {
        event.preventDefault();
        if (selectedResultItem && currentMeshInstance) {
            selectedResultItem.select = true;
        }
    }

    return (
            <div
                tabIndex={-1}
                className="relative pl-1.5"
                ref={ref}
            >
                <div className="relative rounded-md shadow-sm">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <MagnifyingGlassIcon className="h-4 w-4 text-base-content-300" aria-hidden="true" />
                    </div>
                    <form onSubmit={onFormSubmit}>
                        <input
                            type="text"
                            ref={searchInputRef}
                            className={classNames(
                                show ? 'w-80' : 'w-48',
                                'ui-search-input ui-extended-search pr-7')}
                            onChange={onSearch}
                            onFocus={onFocus}
                            value={query}
                            autoComplete="off"
                            disabled={!uiInteractive}
                        />
                    </form>
                    {!query.length && (
                        <div
                            className={'absolute flex align-middle justify-between w-full pl-10 pr-4 pt-1 top-0 left-0 text-base-content-100 select-none pointer-events-none'}>
                            <span className={'relative top-[0.05rem] pr-3'}>Find</span> <span className={'flex flex-row items-center'}>{isMac() ? (<CommandMacKey />) : (<CtrlKey />)} + <FKey /></span>
                        </div>
                    )}
                    {query.length > 0 && (
                        <div className="absolute right-0 top-0 hidden pr-1 pt-1 sm:block">
                            <button
                                tabIndex={-1}
                                type="button"
                                className="ui-btn-icon-close"
                                onClick={() => {
                                    setQuery('');
                                }}
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                        </div>
                    )}
                </div>
                {show && (
                    <div className={'absolute w-available'}>
                        <ul className="z-10 mt-1 max-h-56 overflow-hidden rounded-btn bg-base-50 py-1 text-base shadow-lg ring-1 ring-base-content-100 ring-opacity-50 focus:outline-none">
                            {!searchResults.length && !filteredFilterList.length && (
                                <li>
                                    <div className="text-center">
                                        <p className="my-4">No results found</p>
                                    </div>
                                </li>
                            )}
                            {searchResults.length > 0 && (
                                <>
                                    <li>
                                        <h2 className="mb-2 mt-2 px-3 text-xs font-semibold text-base-content-500">Results</h2>
                                    </li>
                                    <li>
                                        <ul
                                            className="overflow-auto max-h-48"
                                            onPointerOut={() => onSymbolHover()}
                                        >
                                            {searchResults.map((searchResult) => (
                                                <li
                                                    key={searchResult.symbol.id}
                                                    className={classNames(
                                                        searchResult.outside ? 'grayscale-[50%] opacity-40' : '',
                                                        selectedResultItem?.id === searchResult.symbol.id && !hoveredSymbols ? 'bg-base-200' : '',
                                                        'relative cursor-default select-none py-2 px-3 hover:bg-base-200'
                                                    )}
                                                    onPointerOver={() => onSymbolHover(searchResult)}
                                                    onClick={() => onSymbolClick(searchResult)}
                                                    tabIndex={-1}
                                                >
                                                    <div className="flex items-center">
                                                        <div
                                                            className={`rounded-full shadow-md w-6 h-6 shrink-0 p-1 mr-2`}
                                                            style={{
                                                                backgroundColor: searchResult.symbol.color.getStyle()
                                                            }}
                                                        >
                                                            {searchResult.symbol.iconURL && (
                                                                <img src={searchResult.symbol.iconURL}/>
                                                            )}
                                                        </div>
                                                        {!searchResult.symbol.originalData?.title?.length && (
                                                            <span
                                                                className={classNames('truncate italic text-base-content-500')}>
                                                            untitled
                                                        </span>
                                                        )}
                                                        <span className={classNames('truncate')}>
                                                        {searchResult.symbol.originalData?.title}
                                                    </span>
                                                    </div>
                                                    {
                                                        typeof searchResult.fuseResult.matches !== 'undefined' &&
                                                        searchResult.fuseResult.matches.length > 0 && (
                                                            <div className="">
                                                                {renderMatches(searchResult.fuseResult.matches)}
                                                            </div>
                                                        )}
                                                    {searchResult.outside && (
                                                        <div className="absolute right-0 top-0 text-xs">filtered
                                                            out</div>
                                                    )}
                                                </li>
                                            ))}
                                        </ul>
                                    </li>
                                </>
                            )}
                        </ul>
                        {filteredFilterList.length > 0 && (
                            <ul className={'z-10 mt-1 max-h-40 overflow-hidden rounded-btn bg-base-50 py-1 text-base shadow-lg ring-1 ring-base-content-100 ring-opacity-50 focus:outline-none sm:text-sm'}>
                                <li>
                                    <h2 className="my-2 px-3 text-xs font-semibold text-base-content-500">Filters</h2>
                                </li>
                                <li>
                                    <ul
                                        className="overflow-auto max-h-32"
                                        onPointerLeave={() => {
                                            onFiltersLeave()
                                        }}
                                    >
                                        {filteredFilterList.map((group) => (
                                            <li key={group.id}>
                                                <button
                                                    onClick={() => {
                                                        toggleFilterGroupOpen(group.id)
                                                    }}
                                                    className={classNames(
                                                        group.itemsFound ? 'cursor-default' : '', group.open ? 'bg-base-300 bg-opacity-80 rounded-t-md' : 'hover:bg-base-200 rounded-md',
                                                        'relative flex items-center justify-between w-full text-left p-2 py-1 gap-x-2 text-sm leading-6 font-semibold'
                                                    )}
                                                >
                                                    <span className="flex items-center gap-x-2">
                                                        <ChevronRightIcon
                                                            className={classNames(
                                                                group.itemsFound ? 'invisible pointer-events-none' : 'ui-pointer-events', group.open ? 'rotate-90 text-base-content-500' : 'text-base-content-300',
                                                                'h-5 w-5 shrink-0'
                                                            )}
                                                            aria-hidden="true"
                                                        />
                                                        {group.label}
                                                    </span>
                                                </button>
                                                {(group.open) && (
                                                    <div className="py-1 px-2.5 bg-base-50 rounded-b-md">
                                                        <ul>
                                                            {group.items.map((item) => (
                                                                <li
                                                                    key={item.id}
                                                                    className="relative flex items-start"
                                                                    onPointerOver={() => {
                                                                        onFilterItemHover(item)
                                                                    }}
                                                                >
                                                                    <div className="flex h-6 items-center">
                                                                        <input
                                                                            onChange={(e) => onCheckboxChange(e, group.id, item)}
                                                                            id={item.id}
                                                                            aria-describedby="comments-description"
                                                                            name={item.id}
                                                                            type="checkbox"
                                                                            className=""
                                                                            checked={item.selected as boolean}
                                                                        />
                                                                    </div>
                                                                    <div className="ml-3 text-sm leading-6 w-full">
                                                                        <label htmlFor={item.id}
                                                                               className="font-regular cursor-pointer flex justify-between [word-break:break-word]">
                                                                            <span>{item.label}</span><span
                                                                            className="text-base-content-300 inline-block pl-1 break-normal">{item.numberOfSymbols ?? 0}</span>
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                            </ul>
                        )}
                    </div>
                )}
            </div>
    )
}