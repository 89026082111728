import * as THREE from 'three';

import { BaseObject } from '../common/BaseObject';
import { ExtendedMaterial } from '../utils/materials/ExtendedMaterial';
import { ExtensionGreyscale } from '../utils/materials/extensions/ExtensionGreyscale';
import { SYMBOL_CIRCLE_RADIUS, SYMBOL_HEIGHT, SYMBOL_SIZE } from '../common/constants';
import { Repository } from '../common/Repository';

export class SymbolPad extends BaseObject{

    constructor(){
        super();

        this.three.castShadow = false;
    }

    override getGeometry() {
        const shape = new THREE.Shape();
		const angleStep = Math.PI * 0.5;
		const size = SYMBOL_SIZE;
		const radius = SYMBOL_CIRCLE_RADIUS;

		shape.absarc(size / 2 - radius, size / 2 - radius, radius, angleStep * 0, angleStep * 1);
		shape.absarc(-size / 2 + radius, size / 2 - radius, radius, angleStep * 1, angleStep * 2);
		shape.absarc(-size / 2 + radius, -size / 2 + radius, radius, angleStep * 2, angleStep * 3);
		shape.absarc(size / 2 - radius, -size / 2 + radius, radius, angleStep * 3, angleStep * 4);

        console.log(shape.getPoints(12));

		const geometry = new THREE.ExtrudeGeometry(shape, {
			depth: SYMBOL_HEIGHT,
			bevelEnabled: false,
			bevelThickness: 0,
			bevelSize: 0,
			bevelSegments: 0,
			curveSegments: 12
		}).rotateX(-Math.PI/2);

        return geometry;
    }

    override getMaterial(){
        let fadeColor;

        if(Repository.mesh!.activeTheme === "cosmosLight"){
            fadeColor = Repository.mesh!.cosmos.groundColorLight.clone();
        }else if(Repository.mesh!.activeTheme === "cosmosDark"){
            fadeColor = Repository.mesh!.cosmos.groundColorDark.clone();
        }

        const material = new (ExtendedMaterial as any)(
            THREE.MeshLambertMaterial,
            [ExtensionGreyscale],
            {
                color: 0xff0000,
                fadeColor: fadeColor
                // wireframe: false
            },
            { debug: false }
        );

        return material;
    }
}