import * as THREE from 'three';

import * as signals from 'signals';

import { gsap } from "gsap";

import { Repository } from '../common/Repository';
import { SymbolIcon } from './SymbolIcon';
import { BaseObject } from '../common/BaseObject';
import { GROUND_OFFSET, INTERACTION_ANIMATION_SPEED, LEGEND_POSITION_Y_OFFSET, PREVENT_SHADOW_LEAK, PREVENT_Z_FIGHTING, SYMBOL_APP_HEIGHT, SYMBOL_HEIGHT } from '../common/constants';
import { SymbolLegend } from './SymbolLegend';
import { SymbolShape } from './SymbolShape';
import { FilterDefinition, FilterGroupDefinition } from '../../store/Filter';
import { ContextMenu } from '../utils/interaction/ContextMenu';
import { getMakeIconUrl } from '../utils/utils';
import { TBaseLink, TBaseSymbol } from '../Mesh';
import { VirtualLink } from '../links/types/VirtualLink';
import { BaseSharedSymbol } from './BaseSharedSymbol';
import { SymbolPad } from './SymbolPad';

export interface SerializedSymbol {
    id: string;
	external_id: string;
	type: string;
	typeLabel?: string,
	theme?: string;
	title?: string;
	url?: string;
	attributes: unknown;
	isAutomation: boolean; // this flag should be in database soon
	isDuplicate?: boolean;
	originalId?: string;
	isShared?: boolean;
}

export interface SymbolDisplayableProp {
	key: string,
	label: string
}

export abstract class BaseSymbol extends BaseObject{

	readonly isSymbol = true;
	readonly isShared = false;

	protected _depth = 1;

	interactionLayer = 1;

	private _type = "";
	private _shapeType: "round" | "rect" | "tri" = "round";

	private _title = "";
	private _url = "";

	originalData: SerializedSymbol | null = null;
	groupId: string = "";

	protected _imgIconUrl?: string;
	protected _preventOpenUrl = false;
	protected _openUrlNewTab = true;

	pad: SymbolPad;

	shape: SymbolShape;

	protected _icon: SymbolIcon;
	private _iconSlug = "";
	private _iconURL = "";

	legend: SymbolLegend;

	readonly links: TBaseLink[] = [];
	readonly inputLinks: TBaseLink[] = [];
	readonly outputLinks: TBaseLink[] = [];

	private _hover = false;
	private _click = false;
	private _doubleclick = false;
	private _select = false;

	private _visible = false;
	private _filtered = false;
	private _muted = false;
	private _spotlight = false;

	spotlightLevel = 0;

	virtualLinks: VirtualLink[] = [];

	hovered: signals.Signal;
	unhovered: signals.Signal;

	clicked: signals.Signal;
	doubleclicked: signals.Signal;

	selected: signals.Signal;
	unselected: signals.Signal;

	contextmenued: signals.Signal;

	linked: signals.Signal;
	unlinked: signals.Signal;

	private _greyscaleTo: gsap.QuickToFunc;
	private _hideTo: gsap.QuickToFunc;
	private _shapeScaleTo: gsap.QuickToFunc;
	private _iconPositionTo: gsap.QuickToFunc;
	private legendOpacityTo: gsap.QuickToFunc;

	public detailBaseDisplayProps: SymbolDisplayableProp[] = [
		{
			key: 'type',
			label: 'Type',
		},
		{
			key: 'url',
			label: 'Url',
		}, {
			key: 'external_id',
			label: 'ID'
		}
	];

    constructor () {
		super();

		this.pad = new SymbolPad();
		this.three.add(this.pad.three);

		this.shape = new SymbolShape();
		this.three.add(this.shape.three);

		this._icon = new SymbolIcon();
		this._icon.three.position.y = this.shape.three.position.y + SYMBOL_HEIGHT + PREVENT_Z_FIGHTING;

		this.three.add(this._icon.three);

		this.legend = new SymbolLegend();
		this.legend.three.position.y = -LEGEND_POSITION_Y_OFFSET;

		this.three.add(this.legend.three);

		this.hovered = new signals.Signal();
		this.unhovered = new signals.Signal();

		this.clicked = new signals.Signal();
		this.doubleclicked = new signals.Signal();

		this.selected = new signals.Signal();
		this.unselected = new signals.Signal();

		this.contextmenued = new signals.Signal();
		this.contextmenued.add(this.onContextMenu, this);

		this.linked = new signals.Signal();
		this.linked.add(this.onLinked, this);

		this.unlinked = new signals.Signal();
		this.unlinked.add(this.onUnlinked, this);

		// Quick tween functions to boost performance even more
		this._greyscaleTo = gsap.quickTo(this, "greyscale", { duration: INTERACTION_ANIMATION_SPEED, ease: "none" });
		this._hideTo = gsap.quickTo(this.three.position, "y", { duration: INTERACTION_ANIMATION_SPEED, ease: "power2.inOut", onUpdate: () => { this.matrixNeedsUpdate = true; } });
		this._shapeScaleTo = gsap.quickTo(this.shape.three.scale, "y", { duration: INTERACTION_ANIMATION_SPEED, ease: "power2.inOut", onUpdate: () => { this.shape.matrixNeedsUpdate = true; } });
		this._iconPositionTo = gsap.quickTo(this._icon.three.position, "y", { duration: INTERACTION_ANIMATION_SPEED, ease: "power2.inOut", onUpdate: () => { this._icon.matrixNeedsUpdate = true; } });
		this.legendOpacityTo = gsap.quickTo(this.legend, "opacity", { duration: INTERACTION_ANIMATION_SPEED, ease: "none" });
    }

	get detailDisplayProps() {
		return this.detailBaseDisplayProps;
	}

	get type() {
		return this._type;
	}

	set type(value) {
		if(value === this._type){return}
		this._type = value;

		this.shapeType = "round";

		this.shape.instancedOrBatchedMesh = Repository.symbolsShapesMeshes.get(this.type)!;

		this._icon.instancedOrBatchedMesh = Repository.symbolsIconsMesh!;
		this.legend.instancedOrBatchedMesh = Repository.symbolsLegendsMesh!;
	}

	get shapeType() {
		return this._shapeType;
	}

	set shapeType(value) {
		if(value === this._type){return}

		this._shapeType = value;
	}

	override get globalInstanceId(): number {
		return super.globalInstanceId;
	}

	override set globalInstanceId(value: number) {
		super.globalInstanceId = value;

		this.shape.globalInstanceId = value;
	}

	override get matrixNeedsUpdate() {
		return this._matrixNeedsUpdate;
	}

	override set matrixNeedsUpdate(value) {
		this._matrixNeedsUpdate = value;

		this.shape.matrixNeedsUpdate = value;
		this._icon.matrixNeedsUpdate = value;
		this.legend.matrixNeedsUpdate = value;
	}

	override get color() {
		return this._color;
	}

	override set color(value: THREE.Color) {
		if (value === this._color) return;
		this._color = value;
		this.colorNeedsUpdate = true;

		this.shape.color = value;
	}

	override get opacity() {
		return this._opacity;
	}

	override set opacity(value) {
		if(this._opacity === value) return;
		this._opacity = value;
		this.opacityNeedsUpdate = true;

		this.shape.opacity = value;
		this._icon.opacity = value;
	}

	override get greyscale() {
		return this._greyscale;
	}

	override set greyscale(value) {
		if(this._greyscale === value) return;
		this._greyscale = value;
		this.greyscaleNeedsUpdate = true;

		this.shape.greyscale = value;
	}

	get preventOpenUrl() {
		return this._preventOpenUrl;
	}

	get visible() {
		return this._visible;
	}

	set visible(value) {
		if(this._visible === value) return;

		this._visible = value;

		if(!this._filtered){
			this.highlightOut();
			if(!this._visible){
				this.muted = true;
				this.hideIn();
			}else{
				this.muted = false;
				this.hideOut();
			}
		}
	}

	get filtered() {
		return this._filtered;
	}

	set filtered(value) {
		if(this._filtered === value) return;

		this._filtered = value;

		if(this._visible){
			this.highlightOut();
			if(this._filtered){
				this.muted = true;
				this.hideIn();
			}else{
				this.muted = false;
				this.hideOut();
			}
		}
	}

	get muted() {
		return this._muted;
	}

	set muted(value) {
		if(this._filtered || !this.visible){ return }
		if(this._muted === value) return;

		this._muted = value;

		if(this._muted){
			this.muteIn();
		}else{
			this.muteOut();
		}
	}

	get spotlight() {
		return this._spotlight;
	}

	set spotlight(value) {
		if(this._filtered || !this.visible){ return }

		this._spotlight = value;

		if(this._spotlight){
			this.showVirtualLinks();

			this.muted = false;
			this.highlightIn();
		}else{
			this.hideVirtualLinks();

			this.muted = false;
			this.highlightOut();
		}
	}

	get url(): string {
		return this._url;
	}

	set url(value: string) {
		if (value === this._url) return;
		this._url = value;
	}

	get title(): string {
		return this._title;
	}

	set title(value: string) {
		if (value === this._title) return;
		this._title = value;
		this.legend.title = value;
	}

	get iconSlug() {
		return this._iconSlug;
	}

	set iconSlug(value) {
		if (value === this._iconSlug) return;
		this._iconSlug = value;
		this._icon.slug = value;
		this.iconURL = getMakeIconUrl(value);
	}

	get iconURL() {
		return this._iconURL;
	}

	set iconURL(value) {
		if (value === this._iconURL) return;
		this._iconURL = value;
	}

	get hover() {
		return this._hover;
	}

	set hover(value) {
		if(this._hover === value || this._filtered){ return }

		this._hover = value;

		if(this._hover){
			this.hovered.dispatch(this);
		}else{
			this.unhovered.dispatch(this);
		}
	}

	get click() {
		return this._select;
	}

	set click(value) {
		if(this._filtered){ return }

		this._click = value;

		if(this._click){
			this.clicked.dispatch(this);
		}
	}

	get doubleclick() {
		return this._select;
	}

	set doubleclick(value) {
		if(this._filtered){ return }

		this._doubleclick = value;

		if(this._doubleclick){
			this.doubleclicked.dispatch(this);
		}
	}

	get select() {
		return this._select;
	}

	set select(value) {
		if(this._filtered){ return }

		this._select = value;

		if(this._select){
			this.selected.dispatch(this);
		}else{
			this.unselected.dispatch(this);
		}
	}

	/**
	 * Iterate over all links and find the neighboring symbols.
	 */
	get neighbors(): TBaseSymbol[] {
		const links = this.links.filter(link => !link.isVirtual);
		const symbols = links.map(link => link.target === this ? link.source! : link.target! );
		return symbols.filter(symbol => !!symbol);
	}

	get shared(): BaseSharedSymbol | undefined {
		const shared = this.neighbors.filter(symbol => symbol.isShared);

		if(shared.length > 0){
			return shared[0] as BaseSharedSymbol;
		}else{
			return undefined;
		}
	}

	/**
	 * Iterate over all neighbors, find the shared symbol, and then find all duplicates by getting all neighbors of the shared symbol minus itself.
	 */
	get duplicates(): TBaseSymbol[] {
		const duplicates: TBaseSymbol[] = [];

		this.shared?.neighbors.forEach((neighborSymbol) => {
			if(neighborSymbol.id !== this.id){
				duplicates.push(neighborSymbol);
			}
		});

		return duplicates;
	}

	createVirtualLinks(){
		const duplicates = this.duplicates;

		if(duplicates.length > 0){
			duplicates.forEach((symbol)=>{
				const virtualLink = new VirtualLink();
				virtualLink.type = "virtualLink";
				virtualLink.source = Repository.symbols.get(this.id);
				virtualLink.target = Repository.symbols.get(symbol.id);

				this.virtualLinks.push(virtualLink);

				Repository.virtualLinks.set(virtualLink.id, virtualLink);
				Repository.mesh?.virtualLinks.push(virtualLink);
			});
		}
	}

	private showVirtualLinks() {
		for(let i = 0; i < this.virtualLinks.length; i++){
			if(!this.virtualLinks[i].target?.filtered){
				this.virtualLinks[i].visible = true;
			}
		}
	}

	private hideVirtualLinks(){
		for(let i = 0; i < this.virtualLinks.length; i++){
			this.virtualLinks[i].visible = false;
		}
	}

	onLinked(type: string, link: TBaseLink){
		if(type === "input"){
			this.inputLinks.push(link);
		} else if(type === "output"){
			this.outputLinks.push(link);
		}

		this.links.push(link);
	}

	onUnlinked(type: string, link: TBaseLink){
		if(type === "input"){
			this.inputLinks.splice(this.inputLinks.indexOf(link));
		} else if(type === "output"){
			this.outputLinks.splice(this.outputLinks.indexOf(link));
		}

		this.links.splice(this.links.indexOf(link));
	}

	onContextMenu(point: THREE.Vector2){
		const cm = new ContextMenu();
		this.createContextMenu(cm, point);
	}

	muteIn(){
		this._greyscaleTo(1);
		this.legendOpacityTo(0);
	}

	muteOut(){
		this._greyscaleTo(0);
		this.legendOpacityTo(1);
	}

	highlightIn(){
		const scale =  THREE.MathUtils.lerp(2, 1.5, this.spotlightLevel );
		this.spotlightLevel = 0;
		this._shapeScaleTo(scale);
		this._iconPositionTo(SYMBOL_HEIGHT * scale + PREVENT_Z_FIGHTING);
	}

	highlightOut(){
		this._shapeScaleTo(1);
		this._iconPositionTo(SYMBOL_HEIGHT + PREVENT_Z_FIGHTING);
	}

	hide(){
		this.greyscale = 1;
		this.three.position.y = -(SYMBOL_APP_HEIGHT + GROUND_OFFSET + PREVENT_Z_FIGHTING + PREVENT_SHADOW_LEAK);
		this.matrixNeedsUpdate = true;
		this.legend.opacity = 0;
	}

	hideIn(){
		this._greyscaleTo(1);
		this._hideTo(-(SYMBOL_APP_HEIGHT + GROUND_OFFSET + PREVENT_Z_FIGHTING + PREVENT_SHADOW_LEAK));
		this.legendOpacityTo(0);

		this.hideVirtualLinks();
	}

	hideOut(){
		this._greyscaleTo(0);
		this._hideTo(0);
		this.legendOpacityTo(1);
	}

	protected createContextMenu(cm: ContextMenu, point: THREE.Vector2): void {
		if (this.url) {
			cm.addItem('Open URL', 'arrow-up-right-from-square', () => {
				window.open(this.url, '_blank');
			})
		}
		if(this.shared){
			cm.addItem('Show all uses', 'diagram-project', () => {
				Repository.mesh!.showUsages = true;
				this.select = true;
			})
		}

		cm.updatePosition(point);
	}

	override getGeometry() {
        return this.shape.getGeometry();
    }

	override getMaterial() {
        return this.shape.getMaterial();
    }

    toJSON(): SerializedSymbol {
		return {
			id: this.id,
			external_id: this.originalData!.external_id,
			type: Repository.typeOf(this),
			theme: this.color.getHexString(),
			title: this.title,
			url: this.url,
			attributes: {},
			isAutomation: !!this.originalData?.isAutomation,
		}
	}

	fromJSON(object: SerializedSymbol) {
		this.originalData = object;
		if (object.id) this.id = object.id;
		this.color = new THREE.Color(object.theme);
		if (object.url) this.url = object.url;
		this.title = object.title || "[no-title]";
	}

	static getFilterGroupDefinition(): FilterGroupDefinition[] {
		return [];
	}

	static getFilterDefinition(): FilterDefinition[] {
		return [];
	}

	override dispose(){
		this.hovered.removeAll();
		this.unhovered.removeAll();
		this.selected.removeAll();
		this.unselected.removeAll();
		this.contextmenued.removeAll();
		this.linked.removeAll();
		this.unlinked.removeAll();

		this.shape?.dispose();
		this._icon?.dispose();
		this.legend?.dispose();

		super.dispose();
	}

}