import * as THREE from 'three';

import { BaseObject } from '../common/BaseObject';
import { ExtendedMaterial } from '../utils/materials/ExtendedMaterial';
import { ExtensionBatchedOpacity } from '../utils/materials/extensions/ExtensionBatchedOpacity';
import { LINK_WIDTH, SCALE_FACTOR } from '../common/constants';
import { TBaseSymbol } from '../Mesh';

export class LinkShape extends BaseObject{

    interactionLayer = 1;

    private updateCount = 0;

    constructor(){
        super();

        this.three.castShadow = true;
    }

    override getMaterial(){
        const material = new (ExtendedMaterial as any)(
            THREE.MeshLambertMaterial,
            [ExtensionBatchedOpacity],
            {
                color: 0xffffff,
				transparent: true,
				alphaToCoverage: true, // This is super important so transparent geometries don't clip the ones behind even when they are fully transparent
                // wireframe: false
            },
            { debug: false }
        );

		return material;
    }

    updateGeometry(source: TBaseSymbol, target: TBaseSymbol){
		if (!this.instancedOrBatchedMesh) return;

		// Define the start and end points
		const pointSource = new THREE.Vector3(0, 0, 0);
		pointSource.y = 0 + (Math.random() * 0.1) / SCALE_FACTOR; // Very important to avoid z-fighting

		const pointTarget = target.three.position.clone().sub(source.three.position);
		pointTarget.y = 0 + (Math.random() * 0.1) / SCALE_FACTOR; // Very important to avoid z-fighting

		// Generate vertices, normals, and UVs for a single segment
		const vertices = [];
		const normals = [];
		const indices = [0, 1, 2, 2, 1, 3];
		const uv = [];

		// Calculate the tangent and normal
		const tangent = new THREE.Vector3().subVectors(pointTarget, pointSource).normalize();
		const up = new THREE.Vector3(0, 1, 0);
		const normal = new THREE.Vector3().crossVectors(tangent, up).normalize().multiplyScalar(LINK_WIDTH / 2);

		const leftSource = new THREE.Vector3().copy(pointSource).sub(normal);
		const rightSource = new THREE.Vector3().copy(pointSource).add(normal);
		const leftTarget = new THREE.Vector3().copy(pointTarget).sub(normal);
		const rightTarget = new THREE.Vector3().copy(pointTarget).add(normal);

		vertices.push(leftSource.x, leftSource.y, leftSource.z);
		vertices.push(rightSource.x, rightSource.y, rightSource.z);
		vertices.push(leftTarget.x, leftTarget.y, leftTarget.z);
		vertices.push(rightTarget.x, rightTarget.y, rightTarget.z);

		// Normals for lighting
		const faceNormal = new THREE.Vector3().crossVectors(normal, tangent).normalize();
		normals.push(faceNormal.x, faceNormal.y, faceNormal.z); // For left source vertex
		normals.push(faceNormal.x, faceNormal.y, faceNormal.z); // For right source vertex
		normals.push(faceNormal.x, faceNormal.y, faceNormal.z); // For left target vertex
		normals.push(faceNormal.x, faceNormal.y, faceNormal.z); // For right target vertex

		uv.push(0, 0);
		uv.push(1, 0);
		uv.push(0, 1);
		uv.push(1, 1);

		// Create the geometry
		const geometry = new THREE.BufferGeometry();
		geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
		geometry.setAttribute('normal', new THREE.Float32BufferAttribute(normals, 3));
		geometry.setAttribute('uv', new THREE.Float32BufferAttribute(uv, 2));
		geometry.setIndex(indices);

		if(this.instancedOrBatchedMesh && (this.instancedOrBatchedMesh as THREE.BatchedMesh).isBatchedMesh){
			if(this.updateCount === 0){
				const geometryId = (this.instancedOrBatchedMesh as THREE.BatchedMesh).addGeometry( geometry );
				this.instanceId = (this.instancedOrBatchedMesh as THREE.BatchedMesh).addInstance(geometryId);
			}else{
				(this.instancedOrBatchedMesh as THREE.BatchedMesh).setGeometryAt( this.instanceId, geometry );
			}
		}

		this.updateCount++;
	}
}